import * as i0 from '@angular/core';
import { Injectable, Inject, NgModule, Directive } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';
import * as i1$1 from '@angular/router';
class BsCoreAuthenticatedService {
  static {
    this.keyStorage = 'bsCoreAuthenticated';
  }
  constructor(env) {
    this.env = env;
    this.data$ = new BehaviorSubject(null);
    const stored = localStorage.getItem(BsCoreAuthenticatedService.keyStorage);
    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        // At least have one hour before expire
        if ((parsed.expire - 3600) * 1000 > Date.now()) {
          this.set(parsed);
        }
      } catch (Error) {}
    }
    const authCookie = env.authCookie ?? true;
    if (this.data$.getValue() === null && authCookie) {
      const matches = document.cookie.match(/(?=.*(JWT=([a-z0-9A-Z._-]+)))(?=.*(me=([0-9a-f-]{36})))/);
      if (matches) {
        this.set({
          // Only remember the cookie for an hour
          expire: Math.floor(Date.now() / 1000) + 3600,
          token: matches[2],
          identity: {
            type: 'identity.account',
            id: matches[4]
          }
        }, false);
      }
    }
  }
  get() {
    return this.data$.asObservable();
  }
  getIdentity() {
    return this.get().pipe(map(data => data ? data.identity : null));
  }
  isAuthenticated() {
    return this.get().pipe(map(token => token !== null));
  }
  set(data, remember = true) {
    this.data$.next(data);
    if (remember) {
      localStorage.setItem(BsCoreAuthenticatedService.keyStorage, JSON.stringify(data));
    }
  }
  clear() {
    this.data$.next(null);
    localStorage.removeItem(BsCoreAuthenticatedService.keyStorage);
  }
  static {
    this.ɵfac = function BsCoreAuthenticatedService_Factory(t) {
      return new (t || BsCoreAuthenticatedService)(i0.ɵɵinject('env'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreAuthenticatedService,
      factory: BsCoreAuthenticatedService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreAuthenticatedService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }], null);
})();
class BsCorePreferencesService {
  constructor() {
    this.preferences$ = new BehaviorSubject({
      colorScheme: 'system',
      language: 'nl_NL',
      numberFormat: 'nl_NL',
      clock: {
        dateFormat: 'D/M/Y',
        timeFormat: 'H:M:S'
      },
      homepage: null
    });
    const keyStorage = 'bsCorePreferences';
    const saved = localStorage.getItem(keyStorage);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        this.preferences$.next(parsed);
      } catch (Error) {}
    }
    this.preferences$.subscribe(preferences => {
      localStorage.setItem(keyStorage, JSON.stringify(preferences));
    });
  }
  get() {
    return this.preferences$.asObservable();
  }
  set(preferences) {
    this.preferences$.next(preferences);
  }
  static {
    this.ɵfac = function BsCorePreferencesService_Factory(t) {
      return new (t || BsCorePreferencesService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCorePreferencesService,
      factory: BsCorePreferencesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCorePreferencesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class BsCoreFeatureService {
  constructor() {
    this.features$ = new BehaviorSubject({});
    this.features$.next(this.getCached());
  }
  enable(name, cache = false) {
    const features = {
      ...this.features$.getValue()
    };
    features[name] = true;
    if (cache) {
      this.updateCache(name, cache);
    }
    this.features$.next(features);
  }
  disable(name) {
    const features = {
      ...this.features$.getValue()
    };
    features[name] = false;
    this.updateCache(name, false);
    this.features$.next(features);
  }
  check(name) {
    return this.features$.pipe(map(features => features.hasOwnProperty(name) && features[name]), distinctUntilChanged());
  }
  getCached() {
    const cached = localStorage.getItem('features');
    if (cached) {
      try {
        return JSON.parse(cached);
      } catch (Error) {}
    }
    return {};
  }
  updateCache(name, to) {
    const cached = this.getCached();
    cached[name] = to;
    localStorage.setItem('features', JSON.stringify(cached));
  }
  static {
    this.ɵfac = function BsCoreFeatureService_Factory(t) {
      return new (t || BsCoreFeatureService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreFeatureService,
      factory: BsCoreFeatureService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreFeatureService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class BsCoreRpcService {
  constructor(env, http, authenticatedService, preferencesService, featureService) {
    this.env = env;
    this.http = http;
    this.language = null;
    this.token = null;
    this.sudo = false;
    authenticatedService.get().subscribe(data => {
      this.token = data ? data.token : null;
    });
    preferencesService.get().subscribe(preferences => {
      this.language = preferences.language;
    });
    featureService.check('sudo').subscribe(active => {
      this.sudo = active;
    });
  }
  call(serviceName, method, params, version = null, build = null) {
    const serviceConfig = this.env.services[serviceName];
    const headers = {};
    if (serviceConfig.token && this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    if (this.language !== null) {
      headers['Accept-Language'] = this.language;
    }
    if (version) {
      headers['If-Match'] = version.toString();
    }
    if (build) {
      headers['x-build'] = build.toString();
    }
    if (this.sudo) {
      headers['x-sudo'] = '1';
    }
    return this.http.post(serviceConfig.uri + method, params || {}, {
      headers
    }).pipe(catchError(response => {
      let error = response.error;
      if (typeof error === 'object') {
        if (!error.hasOwnProperty('status')) {
          error.status = response.status;
        }
      } else {
        error = {
          status: response.status
        };
      }
      if (response.headers.has('X-Reference')) {
        error['reference'] = response.headers.get('X-Reference');
      }
      return throwError(() => error);
    }));
  }
  static {
    this.ɵfac = function BsCoreRpcService_Factory(t) {
      return new (t || BsCoreRpcService)(i0.ɵɵinject('env'), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BsCoreAuthenticatedService), i0.ɵɵinject(BsCorePreferencesService), i0.ɵɵinject(BsCoreFeatureService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreRpcService,
      factory: BsCoreRpcService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreRpcService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: BsCoreAuthenticatedService
  }, {
    type: BsCorePreferencesService
  }, {
    type: BsCoreFeatureService
  }], null);
})();
class BsCoreAuthenticatedGuard {
  constructor(router, authenticatedService) {
    this.router = router;
    this.authenticatedService = authenticatedService;
  }
  canActivate(route, state) {
    return this.authenticatedService.isAuthenticated().pipe(map(isAuthenticated => {
      if (isAuthenticated) {
        return true;
      }
      return this.router.createUrlTree(['/me/sign-in'], {
        queryParams: {
          origin: state.url
        }
      });
    }));
  }
  static {
    this.ɵfac = function BsCoreAuthenticatedGuard_Factory(t) {
      return new (t || BsCoreAuthenticatedGuard)(i0.ɵɵinject(i1$1.Router), i0.ɵɵinject(BsCoreAuthenticatedService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreAuthenticatedGuard,
      factory: BsCoreAuthenticatedGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreAuthenticatedGuard, [{
    type: Injectable
  }], () => [{
    type: i1$1.Router
  }, {
    type: BsCoreAuthenticatedService
  }], null);
})();
class BsCoreGuestGuard {
  constructor(router, authenticatedService) {
    this.router = router;
    this.authenticatedService = authenticatedService;
  }
  canActivate() {
    return this.authenticatedService.isAuthenticated().pipe(map(isAuthenticated => {
      if (!isAuthenticated) {
        return true;
      }
      return this.router.parseUrl('/');
    }));
  }
  static {
    this.ɵfac = function BsCoreGuestGuard_Factory(t) {
      return new (t || BsCoreGuestGuard)(i0.ɵɵinject(i1$1.Router), i0.ɵɵinject(BsCoreAuthenticatedService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreGuestGuard,
      factory: BsCoreGuestGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreGuestGuard, [{
    type: Injectable
  }], () => [{
    type: i1$1.Router
  }, {
    type: BsCoreAuthenticatedService
  }], null);
})();
class BsCoreStaticInjectorService {
  constructor(injector) {
    BsCoreStaticInjectorService.injector = injector;
  }
  static {
    this.ɵfac = function BsCoreStaticInjectorService_Factory(t) {
      return new (t || BsCoreStaticInjectorService)(i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreStaticInjectorService,
      factory: BsCoreStaticInjectorService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreStaticInjectorService, [{
    type: Injectable
  }], () => [{
    type: i0.Injector
  }], null);
})();
class BsCoreModule {
  constructor(_) {}
  static forRoot(environment) {
    return {
      ngModule: BsCoreModule,
      providers: [BsCoreRpcService, {
        provide: 'env',
        useValue: environment
      }]
    };
  }
  static {
    this.ɵfac = function BsCoreModule_Factory(t) {
      return new (t || BsCoreModule)(i0.ɵɵinject(BsCoreStaticInjectorService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BsCoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [BsCoreAuthenticatedGuard, BsCoreGuestGuard, BsCoreStaticInjectorService],
      imports: [HttpClientModule, TranslateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule, TranslateModule],
      providers: [BsCoreAuthenticatedGuard, BsCoreGuestGuard, BsCoreStaticInjectorService]
    }]
  }], () => [{
    type: BsCoreStaticInjectorService
  }], null);
})();
class AbstractBsCoreRpcService {
  constructor(bsCoreRpcService) {
    this.bsCoreRpcService = bsCoreRpcService;
  }
  getBuild() {
    return null;
  }
  callServer(method, params, version) {
    return this.bsCoreRpcService.call(this.getServerName(), method, params, version, this.getBuild());
  }
  static {
    this.ɵfac = function AbstractBsCoreRpcService_Factory(t) {
      return new (t || AbstractBsCoreRpcService)(i0.ɵɵdirectiveInject(BsCoreRpcService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractBsCoreRpcService
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractBsCoreRpcService, [{
    type: Directive
  }], () => [{
    type: BsCoreRpcService
  }], null);
})();
class BsCoreErrorService {
  constructor() {
    this.error$ = new BehaviorSubject(null);
  }
  set(error) {
    this.error$.next(error);
  }
  clear() {
    this.error$.next(null);
  }
  get() {
    return this.error$.asObservable();
  }
  static {
    this.ɵfac = function BsCoreErrorService_Factory(t) {
      return new (t || BsCoreErrorService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsCoreErrorService,
      factory: BsCoreErrorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsCoreErrorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractBsCoreRpcService, BsCoreAuthenticatedGuard, BsCoreAuthenticatedService, BsCoreErrorService, BsCoreFeatureService, BsCoreGuestGuard, BsCoreModule, BsCorePreferencesService, BsCoreRpcService, BsCoreStaticInjectorService };
